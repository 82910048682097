import { render, staticRenderFns } from "./HomeCoach.vue?vue&type=template&id=6cf6d555&scoped=true&"
import script from "./HomeCoach.vue?vue&type=script&lang=js&"
export * from "./HomeCoach.vue?vue&type=script&lang=js&"
import style0 from "./HomeCoach.vue?vue&type=style&index=0&id=6cf6d555&prod&lang=scss&scoped=true&"
import style1 from "./HomeCoach.vue?vue&type=style&index=1&id=6cf6d555&prod&lang=scss&"
import style2 from "./HomeCoach.vue?vue&type=style&index=2&id=6cf6d555&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cf6d555",
  null
  
)

export default component.exports