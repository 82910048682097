<template>
  <div id="Home" class="home-us">
    <div class="banner-con">
      <div class="top-left-con">
        <div class="line-one" v-visible="false"></div>
        <div class="line-two" v-visible="false"></div>
        <div class="line-three"></div>
        <!-- <p class="banner-tit1" v-visible='$i18n.locale=="en"'>
          {{ $t("Home.banner_tit1") }}
        </p> -->
        <!-- <p class="bg-tit1">
                    {{ $t("HomeNew.bg1") }}
                </p> -->
        <p class="bg-tit2">
          {{ $t('HomeNew.bg2') }}
        </p>
        <p class="bg-tit2">
          {{ $t('HomeNew.bg3') }}
          <span>
            <img style="width: 90px" src="../assets/img/2-Icons/icons_connect-wh.svg" alt="" />
          </span>
        </p>
      </div>
      <swiper :options="swiperOption" class="swiper" ref="mySwiper">
        <swiper-slide v-for="(item, index) in swiperImg" :key="index" class="swiper-item1">
          <div class="box">
            <img class="img" :src="item.img" alt="" />
          </div>
          <!-- <div class="top-right-con"></div> -->
          <div class="bottom-left-con"></div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination" />
      </swiper>
    </div>
    <div class="center-background">
      <div class="center-container" style="max-width: 1200px; margin: 0 auto">
        <!-- <div class="info"> -->
        <!-- {{ $t("HomeNew.info") }} -->
        <!-- </div> -->
        <div class="info-title">
          <!-- <div class="info-tit">
                        {{ $t("HomeNew.aboutinfo") }}
                    </div> -->
          <br />
          <div class="info1">
            {{ $t('HomeNew.info1') }}
          </div>
          <br />
          <div class="info2">
            {{ $t('HomeNew.info2') }}
          </div>
          <br />
          <div class="info3">
            {{ $t('HomeNew.info3') }}
          </div>
        </div>
        <div class="list-scroll">
          <div class="list-bg">
            <div v-for="(item, index) in Lists" :key="index" class="list-items">
              <div class="list-img">
                <div class="img-box">
                  <div class="coming-soon-container">
                    <img class="homepage-img" :src="item.img" alt="" />
                  </div>
                </div>
                <div :class="index % 2 == 0 ? 'more_bg more_bg1' : 'more_bg more_bg2'" @click="goPush(item.jump, index)">
                  <div class="more">
                    <div class="txt-l">
                      {{ item.text }}
                    </div>
                    <div class="txt-r">
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="title-text">
          <span>
            <img class="connet" src="../assets/img/2-Icons/icons_connect-left-or.svg" alt="" />
          </span>
          <span class="text-blue">
            {{ $t('HomeNew.title1') }}
          </span>
          <span>
            <img class="connet" src="../assets/img/2-Icons/icons_connect-right-or.svg" alt="" />
          </span>
        </div>
        <div style="width: 80%; margin: 50px auto">
          <div class="swiper-father">
            <swiper :options="swiperOptionCard" v-if="categoriesList.length">
              <swiper-slide class="stop-swiping" align="center" v-for="(item, index) in categoriesList" :key="index">
                <div :class="{ active: position === index, 'sport-box': true }" @click="getDataList('category_id', item.id, index, $event.target)">
                  <div align="center" class="box1">
                    <img v-show="position === index" class="box-img" :src="item.icon_selected_url" alt="" />
                    <img v-show="position !== index" class="box-img" :src="item.icon_unselected_url" alt="" />
                  </div>
                  <div class="box-txt">
                    <!-- {{ item.name_en.toUpperCase() }} -->
                    {{ $i18n.locale == 'en' ? item.name.toUpperCase() : item.name }}
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-button-prev" slot="button-prev">
              <i class="el-icon-arrow-left el-arrow" />
            </div>
            <div class="swiper-button-next" slot="button-next">
              <i class="el-icon-arrow-right el-arrow" />
            </div>
          </div>
        </div>
      </div>
      <div class="center-background">
        <div class="recent-opportunities">
          <div class="recent-left">
            <div class="title-text">
              <p>
                <span class="text-blue">
                  {{ $t('HomeNew.title2') }}
                </span>
              </p>
              <p>
                <span class="text-blue">
                  {{ $t('HomeNew.title3') }}
                </span>
                <span>
                  <img class="connet" src="../assets/img/2-Icons/icons_connect-right-or.svg" alt="" />
                </span>
              </p>
            </div>
            <p>
              <button class="recent-btn1" @click="createJob()">
                {{ $t('HomeNew.btn1') }}
              </button>
            </p>
            <p>
              <button class="recent-btn2" @click="goJob()">
                {{ $t('HomeNew.btn2') }}
              </button>
            </p>
          </div>
          <div class="recent-right">
            <div class="scroll">
              <div v-for="(item, index) in OpportunitiesInfo" :key="index">
                <div class="opportunities" @click="clickJob(item)">
                  <div
                    class="opportunities-bg"
                    :style="{
                      background: item.banner_url ? `url(${item.banner_url}) no-repeat 100%/100% !important` : `#adadad`,
                    }"
                  >
                    <div class="saved" v-if="userId && item.isSaved">
                      <img src="../assets/img/2-Icons/icons_voluntary_coach-w_border.svg" alt="" />
                    </div>
                    <!-- <div class="opportunities-job"> -->
                    <div :id="item.isDatePassed === true ? 'opportunity-disabled' : ''" :class="item.type === 'p' ? 'opportunities-job' : 'opportunities-job opportunities-vol'">
                      {{ item.index }}
                    </div>
                  </div>
                  <div class="opportunities-info">
                    <p :id="item.isDatePassed === true ? 'info-con' : ''" class="ihksport">
                      {{ item.organisation_name }}
                    </p>
                    <p :id="item.isDatePassed === true ? 'info-names' : ''" class="info-active">
                      {{ item.event_name }}
                    </p>
                    <p class="info-sports">
                      <span>
                        <img class="info-img" src="../assets/img/2-Icons/icons_type-of-sports.svg" alt="" />
                      </span>
                      <span class="info-txt">
                        {{ sportType.find((one) => one.name_en == item.sports_type) && sportType.find((one) => one.name_en == item.sports_type).name }}
                      </span>
                    </p>
                    <p class="info-date">
                      <span>
                        <img class="info-img" src="../assets/img/2-Icons/icons_date.svg" alt="" />
                      </span>
                      <span class="info-txt">
                        {{ item.starting_date }} to
                        {{ item.ending_date }}
                      </span>
                    </p>
                    <p class="info-time">
                      <span>
                        <img class="info-img" src="../assets/img/2-Icons/icons_duration.svg" alt="" />
                      </span>
                      <span class="info-txt">
                        {{ item.class_duration_start && item.class_duration_start.slice(0, -3) }}
                        -
                        {{ item.class_duration_end && item.class_duration_end.slice(0, -3) }}
                      </span>
                    </p>
                    <p class="info-place">
                      <span>
                        <img class="info-img" src="../assets/img/2-Icons/icons_location.svg" alt="" />
                      </span>
                      <span class="info-txt">
                        {{ item.location && ($i18n.locale == 'en' ? areas.find((one) => one.id == item.location).name_en : areas.find((one) => one.id == item.location).name_zh_tw) }}
                      </span>
                    </p>
                    <p class="info-line"></p>
                    <p class="ihksport">{{ $t('Jobs.placeholder15') }}</p>
                    <p style="margin: 10px 0; height: 70px">
                      <button :id="item.isDatePassed === true ? 'target-expired' : ''" class="target-btn" v-for="(i, o) in (item.target || '').split(',')" :key="o">
                        {{ i && coachingTarget.find((one) => one.value == i).label }}
                      </button>
                    </p>
                    <p class="post-date">{{ $t('Jobs.post_date') }} : {{ item.post_date }}</p>
                    <p class="post-date">{{ $t('Jobs.last_edited') }} : {{ item.last_edited }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="video">
          <div class="video-title">
            <div class="video-title1">
              {{ $t('HomeNew.about') }}
            </div>
          </div>

          <!--div class="video-pic"></div -->

          <iframe
            width="800"
            height="480"
            src="https://www.youtube.com/embed/EMRgTnBljjE"
            title="運動教練網上配對平台 教班搵工話咁易"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <!-- DIALOG -->
      <el-dialog :width="screenWidth > 480 ? '60%' : '98%'" :visible.sync="dialogVisible1" :show-close="false" top="6vh">
        <div class="dialog-info">
          <div class="dialog-bg">
            <el-image style="width: 100%" :src="activeJob.banner_url" :preview-src-list="[activeJob.banner_url]" />
            <div :id="activeJob.isDatePassed === true ? 'opportunity-disabled' : ''" :class="activeJob.type === 'p' ? 'opportunities-job' : 'opportunities-job opportunities-vol'">
              {{ activeJob.index }}
            </div>
            <div class="group-btn" v-if="isLogin && !isMyJob && userId !== activeJob.user_id">
              <el-button v-if="activeJob.isDatePassed === false" class="apply-btn" @click="apply(activeJob.id, activeJob.isApply)">{{
                activeJob.isApply ? $t('Jobs.unapply') : screening !== 'My apply Jobs' ? $t('Jobs.apply') : $t('Jobs.unapply')
              }}</el-button>
              <el-button v-if="activeJob.isDatePassed === false" class="save-btn" @click="save(activeJob.id, activeJob.isSaved)">{{
                activeJob.isSaved ? $t('Jobs.unsave') : $t('Jobs.save')
              }}</el-button>
            </div>
            <div class="group-btn" v-if="(isLogin && isMyJob) || userId == activeJob.user_id">
              <el-button class="apply-btn" @click="edit(activeJob.id)">{{ $t('CreateProfiles.edit') }}</el-button>
              <!-- <el-button
                                        class="save-btn"
                                        @click="applied(activeJob.id)"
                                    >
                                        Applied My job</el-button
                                    > -->
            </div>
          </div>
          <div class="dialog-txt">
            <div class="txt-tlt">
              <p :id="activeJob.isDatePassed === true ? 'info-con' : ''" class="ihksport">
                {{ activeJob.organisation_name }}
              </p>
              <p :id="activeJob.isDatePassed === true ? 'info-names' : ''" class="info-active">
                {{ activeJob.event_name }}
              </p>
              <p class="info-event">
                <!-- {{activeJob.event_type }} -->
                {{ activeJob.event_type && eventsTypes.find((one) => one.value == activeJob.event_type).label }}
              </p>
            </div>
            <div class="txt-int">
              <div class="intbox" v-for="(item, index) in txtInt" :key="index">
                <div class="int-box">
                  <img class="txt-img" :src="item.icon" alt="" />
                  <div class="txt-font1">
                    {{ item.txt }}
                  </div>
                  <div class="txt-font2" v-if="index == 2">
                    {{ activeJob[item.str] && activeJob[item.str].slice(0, -3) }}
                  </div>
                  <div class="txt-font2" v-else-if="index == 3">
                    <!-- {{activeJob[item.str]}} -->
                    {{
                      styleLang == 'cn'
                        ? activeJob.location && areas.find((one) => one.id == activeJob.location).name_zh_tw
                        : activeJob.location && areas.find((one) => one.id == activeJob.location).name_en
                    }}
                  </div>
                  <div class="txt-font2" v-else-if="index == 4">
                    {{ activeJob[item.str] && sportType.find((one) => one.name_en == activeJob[item.str]).name }}
                  </div>
                  <div class="txt-font2" v-else>
                    {{ activeJob[item.str] }}
                  </div>

                  <!-- <div class="txt-line"></div> -->
                </div>
              </div>
            </div>
            <div class="bottom-line"></div>
            <div class="txt-info">
              <div class="info-box">
                <span class="info-name"> {{ $t('Jobs.placeholder15') }} </span>
                <span class="info-value">
                  <!-- <button class="target-btn">
                                                Primary School
                                                {{activeJob.target}}
                                            </button> -->
                  <button :id="activeJob.isDatePassed === true ? 'target-expired' : ''" class="target-btn" v-for="(i, o) in (activeJob.target || '').split(',')" :key="o">
                    {{ i && Target.find((one) => one.value == i).label }}
                  </button>
                </span>
              </div>
              <div class="info-box" v-for="(item, index) in txtInfo" :key="index">
                <span class="info-name">{{ item.name }}</span>
                <span class="info-value">
                  {{ item.list ? activeJob[item.value] && item.list.find((one) => one.value == activeJob[item.value]).label : activeJob[item.value] }}
                </span>
              </div>
              <div class="float-right">
                <p class="post-date">
                  {{ $t('Jobs.post_date') }}:
                  {{ activeJob.post_date }}
                </p>
                <p class="post-date">
                  {{ $t('Jobs.last_edited') }}:
                  {{ activeJob.last_edited }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>

      <div class="ranking" style="max-width: 1200px; margin: 0 auto">
        <div class="ranking-list ranking-left" v-if="this.learnList.length > 0">
          <div class="ranking-tlt ranking-tlt1">{{ $t('HomeNew.ranking1') }}</div>
          <div class="ranking-scroll">
            <div class="ranking-frist">
              <div class="ranking-frist-left">
                <div>
                  <img class="frist-header" :src="this.learnList[0].cover_url ? this.learnList[0].cover_url : require('../assets/images/ihk-logo.jpeg')" alt="" />
                </div>
                <div class="frist-icon">1</div>
              </div>
              <div class="ranking-frist-right">
                <div class="ranking-frist-name">{{ $i18n.locale == 'en' ? this.learnList[0].name_en : this.learnList[0].name_zh_tw }}</div>
                <div class="ranking-frist-txt">{{ $t('HomeNew.Finished') }} {{ this.learnList[0].course.unit_count }}: {{ this.learnList[0].course.title }}</div>
              </div>
            </div>
            <div class="ranking-info" v-for="(item, index) in learnList.slice(1, learnList.length)" :key="index">
              <div class="info-num">{{ index + 2 }}</div>
              <div class="ranking-info-left">
                <div>
                  <img class="info-header" :src="item.cover_url ? item.cover_url : require('../assets/images/ihk-logo.jpeg')" alt="" />
                </div>
              </div>
              <div class="ranking-info-right">
                <div class="ranking-info-name">{{ $i18n.locale == 'en' ? item.name_en : item.name_zh_tw }}</div>
                <div class="ranking-info-txt">{{ $t('HomeNew.Finished') }} {{ item.course.unit_count }}: {{ item.course.title }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="ranking-list ranking-right" v-if="this.mostRecordList.length > 0">
          <div class="ranking-tlt ranking-tlt2">
            {{ $t('HomeNew.ranking2') }}
          </div>
          <div class="ranking-scroll">
            <div class="ranking-frist">
              <div class="ranking-frist-left">
                <div>
                  <img class="frist-header frist-header2" :src="this.mostRecordList[0].cover_url" alt="" />
                </div>
                <div class="frist-icon frist-icon2">1</div>
              </div>
              <div class="ranking-frist-right">
                <div class="ranking-frist-name ranking-frist-name2">
                  {{ $i18n.locale == 'en' ? this.mostRecordList[0].name_en : this.mostRecordList[0].name_zh_tw }}
                </div>
                <div class="ranking-frist-txt">{{ $t('HomeNew.Accumulated') }} {{ this.mostRecordList[0].durations }} {{ $t('HomeNew.hours') }}</div>
              </div>
            </div>
            <div class="ranking-info" v-for="(item, index) in mostRecordList.slice(1, mostRecordList.length)" :key="index">
              <div class="info-num info-num2">{{ index + 2 }}</div>
              <div class="ranking-info-left">
                <div>
                  <img class="info-header" :src="item.cover_url" alt="" />
                </div>
              </div>
              <div class="ranking-info-right">
                <div class="ranking-info-name ranking-info-name2">
                  {{ $i18n.locale == 'en' ? item.name_en : item.name_zh_tw }}
                </div>
                <div class="ranking-info-txt">Finished Unit {{ item.durations }} Hours</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { loginThirdPartyUser, learnList, mostRecordList } from '@/api/memberUser'
import { getCategoriesList, getSportTypes } from '@/api/coachProfile'
import { getLatelyList, GetArea, saveJob, unsaveJob, applyJob, unapplyJob, getJobList, getSaveList, getMyList, getApplyList } from '@/api/opportunities'
export default {
  name: 'Home',
  data() {
    return {
      screening: '',
      jobList: [],
      styleLang: 'cn',
      activeJob: {},
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      isLogin: false,
      isMyJob: false,
      learnList: [],
      mostRecordList: [],
      position: '',
      userId: null,
      sportType: [],
      categoriesList: [],
      screenWidth: window.document.body.clientWidth,
      swiperOption: {
        loop: true,
        speed: 1500,
        pagination: {
          el: '.swiper-pagination',
        },
      },
      swiperOptionCard: {
        pagination: {
          el: '.swiper-pagination',
        },
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        speed: 1000,
        noSwiping: true,
        noSwipingClass: 'stop-swiping',
        slidesPerView: 5,
        spaceBetween: 30,
        // centeredSlides: true,
        watchSlidesProgress: true,
      },
      swiperOptionOpportunities: {
        pagination: {
          el: '.swiper-pagination',
        },
        speed: 2500,
        slidesPerView: 4,
        spaceBetween: 10,
        centeredSlides: true,
        loop: true,
        // watchSlidesProgress : true,
      },
      OpportunitiesInfo: [],
      txtInt: [
        {
          txt: this.$t('Jobs.classSize'),
          icon: require('../assets/img/2-Icons/icons_class-size.svg'),
          str: 'class_size',
        },
        {
          txt: this.$t('Jobs.date'),
          icon: require('../assets/img/2-Icons/icons_date.svg'),
          str: 'starting_date',
        },
        {
          txt: this.$t('CreateProfiles.placeholder6'),
          icon: require('../assets/img/2-Icons/icons_duration.svg'),
          str: 'class_duration_start',
        },
        {
          txt: this.$t('CreateProfiles.placeholder7'),
          icon: require('../assets/img/2-Icons/icons_location.svg'),
          str: 'location',
        },
        {
          txt: this.$t('Jobs.typeSprot'),
          icon: require('../assets/img/2-Icons/icons_type-of-sports.svg'),
          str: 'sports_type',
        },
      ],
      txtInt2: [],
      txtInfo: [
        {
          name: this.$t('Jobs.txtInfo1'),
          value: 'skill_level',
          list: this.skillLevel,
        },
        {
          name: this.$t('Jobs.txtInfo2'),
          value: 'medium_of_instruction',
          list: this.mediumOfInstruction,
        },
        {
          name: this.$t('Jobs.txtInfo3'),
          value: 'class_frequency',
          list: this.classFrequency,
        },
        {
          name: this.$t('CreateProfiles.placeholder4'),
          value: 'description',
          list: null,
        },
        {
          name: this.$t('Jobs.txtInfo4'),
          value: 'other_description',
          list: null,
        },
      ],
      Target: [
        {
          value: 'Primary School',
          label: this.$t('CreateProfiles.coachingTarget1'),
        },
        {
          value: 'Secondary School',
          label: this.$t('CreateProfiles.coachingTarget2'),
        },
        {
          value: 'Parent-child',
          label: this.$t('CreateProfiles.coachingTarget3'),
        },
        {
          value: 'Elderly',
          label: this.$t('CreateProfiles.coachingTarget4'),
        },
        {
          value: 'Others',
          label: this.$t('CreateProfiles.coachingTarget5'),
        },
      ],
      cardImg: [
        { img: 'icons_ball', txt: this.$t('HomeNew.sport3') },
        { img: 'icons_racket', txt: this.$t('HomeNew.sport4') },
        { img: 'icons_bat', txt: this.$t('HomeNew.sport5') },
        { img: 'icons_all_sports', txt: this.$t('HomeNew.sport1') },
        { img: 'icons_ball', txt: this.$t('HomeNew.sport2') },
      ],
      swiperImg: [
        {
          img: require('../assets/img/0-Banner/20210307-66.jpg'),
        },
      ],
      Lists: [
        {
          img: require('../assets/img/0-Banner/20210306-65.jpg'),
          text: this.$t('HomeNew.pic1'),
          jump: '/coach-connect/coach-profiles',
        },
        {
          img: require('../assets/img/0-Banner/20211120-130.jpg'),
          text: this.$t('HomeNew.pic2'),
          jump: '/coach-connect/create-profiles',
        },
        {
          img: require('../assets/img/0-Banner/20211120-146.jpg'),
          text: this.$t('HomeNew.pic3'),
          jump: '/coach-connect/opportunities',
        },
        {
          img: require('../assets/img/0-Banner/DSC01004.jpg'),
          text: this.$t('HomeNew.pic4'),
          jump: '/coach-connect/create-job',
        },
      ],
      areas: [],
      coachingTarget: [
        {
          value: 'Primary School',
          label: this.$t('CreateProfiles.coachingTarget1'),
        },
        {
          value: 'Secondary School',
          label: this.$t('CreateProfiles.coachingTarget2'),
        },
        {
          value: 'Parent-child',
          label: this.$t('CreateProfiles.coachingTarget3'),
        },
        {
          value: 'Elderly',
          label: this.$t('CreateProfiles.coachingTarget4'),
        },
        {
          value: 'Others',
          label: this.$t('CreateProfiles.coachingTarget5'),
        },
      ],
      eventsTypes: [
        {
          value: 'Regular Training Course',
          label: this.$t('Jobs.eventsTypes1'),
        },
        {
          value: 'One-off Event',
          label: this.$t('Jobs.eventsTypes2'),
        },
      ],
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
  },
  created() {
    this.handleThirdPartyLogin()
    mostRecordList().then((res) => {
      this.mostRecordList = res.data
    })
    learnList().then((res) => {
      this.learnList = res.data
    })
  },
  mounted() {
    this.isLogin = this.checkLogin()
    this.getArea()
    this.getSports()
    this.getUserId()
    this.getJobList()
  },
  methods: {
    getMyJobs() {
      let parmas
      if (this.userId) {
        parmas = {
          user_id: this.userId,
        }
      }
      getMyList(this.pagination.currentPage, parmas).then((res) => {
        this.jobList = res.data.data
        this.pagination.total = res.data.total
      })
    },
    getMySave() {
      let parmas
      if (this.userId) {
        parmas = {
          user_id: this.userId,
        }
      }
      getSaveList(this.pagination.currentPage, parmas).then((res) => {
        this.jobList = res.data.data
        this.pagination.total = res.data.total
      })
    },
    getMyApply() {
      let parmas
      if (this.userId) {
        parmas = {
          user_id: this.userId,
        }
      }
      getApplyList(this.pagination.currentPage, parmas).then((res) => {
        this.jobList = res.data.data
        this.pagination.total = res.data.total
      })
    },
    getArea() {
      let area
      GetArea().then((res) => {
        area = res.data
        area.map((one) => {
          this.areas = this.areas.concat(one.Areas)
        })
      })
    },
    getSports() {
      getCategoriesList().then((res) => {
        this.categoriesList = [
          {
            icon_selected_url: require('../assets/img/2-Icons/icons_all_sports-or.png'),
            icon_unselected_url: require('../assets/img/2-Icons/icons_all_sports-wh-13.png'),
            id: '',
            name: this.$t('CoachProfiles.All_sports'),
            name_en: 'All sports',
            name_zh_tw: '全部運動',
            sportType: [],
          },
        ]
        this.categoriesList.push(...res.data.data)
      })
      getSportTypes().then((res) => {
        this.sportType = res.data.data
      })
    },
    getDataList(type, name, index, target) {
      console.log(11111)
      if (typeof index === 'number') {
        this.position = index
      }
      this.$router.push({
        path: '/coach-connect/coach-profiles',
        query: { type: name },
      })
    },

    getUserId() {
      this.userId = window.sessionStorage.getItem('user_id')
    },
    getJobList() {
      getLatelyList(this.userId).then((res) => {
        let arrayList = res.data.map((item, index) => {
          const dateToday = moment(new Date(), 'DD-MM-YYYY')
          const dateToCompare = moment(item.ending_date, 'DD-MM-YYYY')
          return dateToday.isSameOrAfter(dateToCompare) ? { ...item, isDatePassed: true } : { ...item, isDatePassed: false }
        })
        this.OpportunitiesInfo = arrayList
        console.log(res)
      })
    },

    clickJob(item) {
      this.activeJob = item
      this.dialogVisible1 = true
    },
    apply(id, isApply) {
      if (isApply || this.screening === 'My apply Jobs') {
        unapplyJob(id)
          .then((res) => {
            this.dialogVisible1 = false
            this.$message({
              message: 'success',
              type: 'success',
            })
            this.getDataList()
          })
          .catch(() => {
            this.$message({
              message: 'fail',
              type: 'error',
            })
            this.getDataList()
          })
      } else {
        this.dialogVisible1 = false
        this.dialogVisible2 = true
      }
    },
    edit(id) {
      this.$router.push({ path: '/coach-connect/create-job', query: { id: id } })
    },
    applied(id) {},
    confirm(id) {
      this.dialogVisible2 = false
      this.dialogVisible3 = true
      applyJob(id, this.applyInfo).then(
        this.getDataList(),
        (this.applyInfo = {
          have_phone: false,
          have_email: false,
          message: '',
        })
      )
    },
    confirmBtn() {
      this.dialogVisible3 = false
    },
    save(id, isSaved) {
      this.dialogVisible1 = false
      if (!isSaved) {
        saveJob(id).then((res) => {
          console.log(id)
          this.getDataList()
        })
      } else {
        unsaveJob(id).then((res) => {
          console.log(id)

          this.getDataList()
        })
      }
    },
    goPush(page, index) {
      console.log(this.checkLogin())
      let isLogin = this.checkLogin()
      if (index == 1) {
        if (!isLogin) {
          this.open('profile')
        } else {
          this.$router.push(page)
        }
      } else if (index == 3) {
        if (!isLogin) {
          this.open('job')
        } else {
          this.$router.push(page)
        }
      } else {
        this.$router.push(page)
      }
    },
    createJob() {
      let isLogin = this.checkLogin()
      if (!isLogin) {
        this.open('job')
      } else {
        this.$router.push('/coach-connect/create-job')
      }
    },
    goJob() {
      this.$router.push('/coach-connect/opportunities')
    },
    checkLogin() {
      let token = window.sessionStorage.getItem('token')
      return token == null ? false : true
    },
    open(item) {
      let txt
      if (item == 'profile') {
        txt = this.$t('CoachProfiles.please')
      } else if (item == 'job') {
        txt = this.$t('CoachProfiles.please1')
      }
      this.$prompt.alert(txt, this.$t('CoachProfiles.welcome'), {
        confirmButtonText: this.$t('CoachProfiles.confirm'),
        confirmButtonClass: 'box-btn',
      })
    },
    getList() {
      let parmas = {}
      if (this.userId) {
        parmas = {
          user_id: this.userId,
        }
      }
      if (Object.keys(this.searchList).length > 0) {
        if (this.searchList.search && this.search) {
          parmas.search = this.search
        }
        if (this.searchList.location) {
          parmas.location = this.locationselect
        }
        if (this.searchList.sports_type) {
          parmas.sports_type = this.sportselect
        }
        if (this.searchList.last_edited) {
          parmas.sort = 'updatedAt'
        }
        if (this.searchList.lastest) {
          parmas.sort = 'createdAt'
        }
      }
      getJobList(this.pagination.currentPage, parmas).then((res) => {
        this.jobList = res.data.data
        this.pagination.total = res.data.total
      })
    },
    handleThirdPartyLogin: async function () {
      let res = window.location.href.match(/code=[^&]+&?/g)
      if (!res) {
        return
      }
      let code = res[0].substring(5, res[0].length - 1)
      let party = window.sessionStorage.getItem('party')
      try {
        let token = await loginThirdPartyUser(party, code)
        if (token.result == 'success') {
          window.sessionStorage.setItem('token', token.data.access_token)
          window.sessionStorage.setItem('third_party', true)
          this.$message({
            message: this.$t('Home.login_success'),
            type: 'success',
          })
          window.location.href = '/#/inspire'
        }
      } catch (err) {
        this.$message({
          message: this.$t('Home.login_fail'),
          type: 'error',
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.line {
  background-color: #d2d2d2;
  height: 1px;
  margin: 60px 10%;
}
.center-background {
  background: url('../assets/img/bg-or.jpg');
}
.center-container {
  max-width: 1200px;
  margin: 0 auto;
}
.saved {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
}
.video {
  .video-title {
    background-color: #fff;
    height: 250px;
    position: relative;

    .video-title-bg {
      background-color: #f15922;
      color: #fff;
      width: 600px;
      height: 50px;
      font-size: 32px;
      font-weight: bold;
      position: absolute;
      bottom: 0;
      left: 34%;
    }
  }
  .video-content {
    background-color: #fff7f7;
    .video-iframe {
      max-width: 1200px;
      margin: 50px auto;
      background-color: #eee;
    }
  }
}
.home-us {
  .banner-con {
    position: relative;
    .top-left-con {
      width: 62%;
      height: 500px;
      background: #28327b;
      position: absolute;
      top: 0;
      left: 0;
      transform: skew(-15deg) translateX(-9%);
      z-index: 2;
      .line-one {
        width: 338px;
        height: 2px;
        background: #fff;
        position: absolute;
        top: 24%;
        right: 2%;
      }
      .line-two {
        width: 120px;
        height: 2px;
        background: #fff;
        position: absolute;
        top: 42%;
        right: 2%;
      }
      .banner-tit1 {
        width: auto;
        margin-top: 18%;
        margin-left: 2%;
        padding-left: 13%;
        text-align: left;
        font-size: 70px !important;
        color: #fff;
        transform: skew(6deg);
        font-weight: bold;
      }
      .banner-tit2 {
        width: auto;
        margin-top: -2%;
        margin-left: 4%;
        padding-left: 10%;
        text-align: left;
        font-size: 70px !important;
        color: #fff;
        transform: skew(6deg);
        font-weight: bold;
        // font-family: "NotoSansCJKtc-Black";
      }
      .bg-tit1 {
        margin-top: 150px;
        height: 36px;
        font-size: 32px !important;
        font-weight: 400;
        line-height: 42px;
        color: #ffffff;
        transform: skew(13deg);
        position: relative;
        left: -80px;
      }
      .bg-tit2 {
        padding-top: 100px;
        margin: 0 auto;
        font-size: 75px;
        font-weight: bold;
        line-height: 75px;
        color: #ffffff;
        font-size: 70px !important;
        color: #fff;
        opacity: 1;

        transform: skew(13deg);
      }
      .more {
        position: absolute;
        bottom: 20px;
        right: 20px;
        color: #fff;
        transform: skew(15deg);
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .swiper {
      height: 620px;
      .swiper-item1 {
        position: relative;
        overflow: hidden;
        .box {
          position: absolute;
          right: 0;
          bottom: 0;
          height: 100%;
          width: 60%;
          overflow: hidden;
          transform: skew(-15deg) translateX(12%);
          .img {
            position: absolute;
            left: 0;
            bottom: 0;
            height: 100%;
            width: 130%;
            transform: skew(15deg) translateX(-9%);
            object-fit: cover;
          }
        }
        .top-right-con {
          width: 40%;
          height: 9.1%;
          background: #f15922;
          position: absolute;
          top: 3%;
          right: 0;
          transform: skew(-15deg) translateX(2.5%);
        }
        .bottom-left-con {
          width: 40%;
          height: 15%;
          background: #f15922;
          position: absolute;
          bottom: 5%;
          left: 0;
          transform: skew(-15deg) translateX(-3%);
        }
      }
    }
  }
  .tit1 {
    font-size: 26px;
    margin: 3% auto;
    width: 80%;
    letter-spacing: 2px;
    text-align: center;
  }
  .tit2 {
    width: 80%;
    font-size: 30px;
    color: #f15922;
    margin: 3% auto;
    p {
      text-align: left;
      margin: 20px 0;
    }
    p:nth-child(1) {
      text-align: center;
      font-weight: bold;
    }
  }
  .list-scroll {
    margin-top: 50px;
    .list-bg {
      width: 95%;
      margin: 0 auto 100px auto;
      display: flex;
      .list-items {
        width: 22%;
        margin: 0 1.5%;
        position: relative;
        cursor: pointer;
        // box-shadow: 0px 0px 30px #D1D4EB;
        .list-img {
          height: 555px;
          // transform: skew(-6deg);
          overflow: hidden;
          position: relative;
          .img-box {
            width: 100%;
            .homepage-img {
              width: 100%;
              height: 450px;
              object-fit: cover;
              margin: 60px 0 0 0;
            }
          }
          .title {
            width: 100%;
            height: 60px;
            line-height: 60px;
            position: absolute;
            top: 0%;
            opacity: 1;
            color: #fff;
            font-size: 33px;
            font-weight: bold;
            background: #28327b;
            text-align: center;
            transition: all ease 0.6s;
          }
          .more_bg {
            width: 100%;
            height: 100px;
            // line-height: 100px;
            color: white;
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 0px;
            // background: rgba(40, 50, 123, 1);
            position: absolute;
            bottom: 0;
            display: flex;
            align-items: center;
            .more {
              display: flex;
              align-items: center;
              text-align: left;
              margin-left: 6%;
              width: 90%;
              justify-content: space-between;
            }
          }
          .more_bg1 {
            background: rgba(40, 50, 123, 1);
          }
          .more_bg2 {
            background: #f15922;
          }
        }
        .item-drow {
          color: #fff;
          padding: 20px;
          transform: translateX(-29px);
          background: rgba(40, 50, 123, 0.7);
          // background: rgba(186, 186, 207, 0.082);
          display: none;
          .content {
            text-align: left;
            .txt {
              font-size: 18px;
              letter-spacing: 0px;
            }
            .txt:nth-child(1) {
              height: 13%;
              font-size: 22px;
              font-weight: bold;
              display: flex;
              white-space: pre;
              align-items: center;
              justify-content: center;
              text-align: center;
            }
            .txt:nth-child(2) {
              font-size: 20px;
              margin-top: 8px;
              font-weight: bold;
              //white-space: pre;
            }
            .txt:nth-child(4) {
              margin-bottom: 15px;
            }
            .txt:nth-child(5) {
              font-weight: bold;
              //white-space: pre;
            }
            .img-box {
              text-align: center;
              .img {
                width: 65%;
              }
            }
          }
        }
      }
      .list-items:hover .item-drow {
        display: block;
        animation: hide 0.5s;
      }
      @keyframes hide {
        0% {
          opacity: 0;
        }
        40% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
}
img.connet {
  width: 50px;
  margin: 0 10px;
}
.info {
  width: 979px;
  height: 54px;
  font-size: 22px;
  font-family: Graphie;
  font-weight: 600;
  line-height: 28px;
  color: #28327b;

  // width: 70%;
  // // height: 41px;
  // font-size: 24px !important;
  // font-family: Graphie;
  // font-weight: 600;
  // color: #28327B;
  padding-top: 100px;
  margin: 0 auto;
}
.card-box {
  display: flex;
  text-align: center;
  justify-content: center;
  // margin-top: 100px;
  .card {
    margin: 100px 20px;
    width: 350px;
    height: 415px;
    overflow: hidden;
    border-radius: 20px !important;
    .card-img {
      width: 100%;
      height: 80%;
    }
    .card-text {
      height: 110px;
      background-color: #28327b;
      font-size: 20px;
      line-height: 110px;
      color: #fff;
      font-weight: bold;
    }
    .card-text:hover {
      background-color: #f15922;
    }
  }
}
.swiper-father {
  position: relative;
}
.swiper-button-next {
  position: absolute;
  right: -10%;
  /*先将原始箭头的颜色设置为透明的  然后设置一个背景图片  达到修改按钮样式的目的*/
  // right: 1.5%;
  // left: auto;
  color: #f15922 !important;
  text-align: center;
  line-height: 50px;
  // background:#f15922;
  // background:url("/assets/images/button_right.png") center center no-repeat;
  background-size: 30px 30px;
  width: 50px;
  height: 50px;
  background: #ffffff;
  border-radius: 50%;
}
.swiper-button-prev {
  position: absolute;
  left: -10%;
  width: 50px;
  height: 50px;
  background: #ffffff;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  // left: 1.5%;
  // right: auto;
  color: #f15922 !important;
  // background:#f15922;
  // background:url("/assets/images/button_left.png") center center no-repeat;
  background-size: 30px 30px;
}
.ranking {
  display: flex;
  margin: 0 auto;
  overflow: auto;
  padding-bottom: 100px;
  justify-content: center;
  .ranking-list {
    width: 522px;
    background: #ffffff;
    box-shadow: 0px 0px 30px #d1d4eb;
    position: relative;
    margin: 200px 43px 0 43px;
    .ranking-scroll {
      max-height: 11rem;
      overflow: auto;
    }
    .ranking-tlt {
      position: absolute;
      top: -41px;
      left: 50px;
      height: 92px;
      width: 424px;
      font-size: 28px;
      text-align: center;
      font-weight: 600;
      line-height: 92px;
      color: #ffffff;
    }
    .ranking-tlt1 {
      background: url('../assets/img/2-Icons/icons_title-block-or.svg');
    }
    .ranking-tlt2 {
      background: url('../assets/img/2-Icons/icons_title-block-blue.svg');
    }
    .ranking-frist {
      width: 522px;
      height: 243px;
      background: #fbf7f6;
      display: flex;
      box-sizing: border-box;
      padding: 72px 23px 20px 23px;
      .ranking-frist-left {
        padding-left: 47px;
        margin-right: 15px;
      }
      .frist-header {
        width: 143px;
        height: 142px;
        border: 3px solid #f15922;
        border-radius: 50%;
        background: url('../assets/img/1-Box-Images/images_profile.png') !important;
      }
      .frist-header2 {
        border: 3px solid #28327b;
      }
      .frist-icon {
        background: url('../assets/img/2-Icons/icons_rank-or.svg');
        position: absolute;
        top: 0;
        left: 15px;
        width: 68px;
        height: 64px;
        font-size: 32px;
        font-weight: bold;
        line-height: 64px;
        text-align: center;
        color: #ffffff;
      }
      .frist-icon2 {
        background: url('../assets/img/2-Icons/icons_rank-blue.svg');
      }
      .ranking-frist-right {
        text-align: left;
        .ranking-frist-name {
          font-size: 22px;
          font-weight: bold;
          line-height: 28px;
          color: #f15922;
        }
        .ranking-frist-name2 {
          color: #28327b;
        }
        .ranking-frist-info {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: #1d1d1d;
        }
      }
    }
    .ranking-info {
      width: 522px;
      // height: 132px;
      background: #fff;
      display: flex;
      box-sizing: border-box;
      padding: 24px 33px 24px 45px;
      .info-num {
        font-size: 32px;
        line-height: 82px;
        font-weight: bold;
        color: #f15922;
      }
      .info-num2 {
        color: #28327b;
      }
      .ranking-info-left {
        .info-header {
          margin: 0 24px;
          width: 87px;
          height: 86px;
          background: url('../assets/img/1-Box-Images/images_profile.png');
          border: 3px solid #d1d4eb;
          border-radius: 50%;
        }
      }
      .ranking-info-right {
        text-align: left;
        .ranking-info-name {
          font-size: 20px;
          font-weight: bold;
          line-height: 28px;
          color: #f15922;
        }
        .ranking-info-name2 {
          color: #28327b;
        }
        .ranking-info-info {
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          color: #1d1d1d;
        }
      }
    }
  }
}
.list-text {
  height: 110px;
  background-color: #28327b;
  font-size: 20px;
  line-height: 110px;
  color: #fff;
  font-weight: bold;
}
.title-text {
  font-size: 30px;
  margin-top: 100px;
}
.text-orange {
  color: #f15922;
  font-weight: bold;
}
.text-blue {
  color: #28327b;
  font-weight: bold;
}
.recent-opportunities {
  padding-top: 80px;
  display: flex;
  .recent-left {
    padding-left: 10%;
    padding-right: 10%;
    background: #fbf7f6;
    .recent-btn1 {
      margin: 10px 0;
      margin-top: 40px;
      width: 348px;
      height: 65px;
      border-radius: 4px;
      line-height: 65px;
      background: #f15922;
      color: #fff;
      border: 1px solid #fff;
    }
    .recent-btn2 {
      margin: 10px 0;
      width: 348px;
      height: 65px;
      border-radius: 4px;
      line-height: 65px;
      background: #fff;
      color: #f15922;
      border: 1px solid #f15922;
    }
  }
  .recent-right {
    min-height: 600px;
    cursor: pointer;
    .scroll {
      width: 70%;
      display: flex;
      overflow: scroll;
    }
  }

  .opportunities {
    width: 500px;
    height: 750px;
    overflow: hidden;
    margin-right: 20px;
    background: #ffffff;
    box-shadow: 0px 0px 15px #d1d4eb;
    text-align: left;
    .opportunities-bg {
      background-color: #adadad;
      height: 30%;
      position: relative;
    }
    .opportunities-job {
      position: absolute;
      bottom: 0;
      width: 1.99rem;
      height: 0.5rem;
      background: #8f98dc;
      transform: skew(-15deg) translateX(-3%);
      font-size: 20px;
      font-weight: 600;
      line-height: 36px;
      color: #ffffff;
      padding-left: 10px;
    }

    .opportunities-vol {
      background: #f15922;
    }

    .opportunities-info {
      padding-left: 33px;
      overflow: hidden;
      .info-line {
        margin-top: 15px;
        width: 394px;
        height: 0px;
        border: 1px solid #d1d4eb;
      }
      .info-img {
        width: 18px;
        height: 18px;
      }
      .info-txt {
        font-size: 20px;
        line-height: 35px;
      }
      .target-btn {
        margin: 10px 0;
        width: 160px;
        height: 30px;
        background: #8f98dc;
        border: 0;
        border-radius: 15px;
        font-size: 14px;
        line-height: 30px;
        color: #ffffff;
        text-align: center;
        margin-right: 5px;
      }

      .post-date {
        margin: 5px 10px 5px 0;
        // margin-top: 5px;
        text-align: end;
        color: #999;
        font-size: 16px;
      }
      .ihksport {
        margin: 15px 0 7px 0;
        // width: 91px;
        min-height: 20px;
        font-size: 20px;
        font-family: Graphie;
        font-weight: 600;
        line-height: 28px;
        color: #8f98dc;
      }
      .info-active {
        margin-bottom: 24px;
        height: 55px;
        // width: 226px;
        min-height: 28px;
        font-size: 28px;
        font-family: Graphie;
        font-weight: bold;
        line-height: 46px;
        color: #28327b;
      }
    }
  }
}
.box1 {
  width: 2rem;
  height: 2rem;
  display: flex;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  .box-img {
    width: 100%;
    height: 100%;
  }
}
#opportunity-disabled {
  position: absolute;
  bottom: 0;
  width: 1.99rem;
  height: 0.5rem;
  background: #b2beb5 !important;
  transform: skew(-15deg) translateX(-3%);
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  color: #ffffff;
  padding-left: 10px;
  overflow: hidden;
}
#info-con {
  margin: 33px 0 7px 0;
  // width: 91px;
  text-align: left;
  min-height: 20px;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #b2beb5;
}
#info-names {
  margin-bottom: 24px;
  height: 55px;
  // width: 226px;
  text-align: left;
  min-height: 28px;
  font-size: 25px;
  font-weight: bold;
  line-height: 46px;
  color: gray;
}
// .box1:hover{
//   background: #f15922;
// }
.box-txt {
  text-align: center;
  color: #28327b;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
}
.box-txt:hover {
  cursor: pointer;
}
.sport-box:hover {
  cursor: pointer;
}
.video {
  margin: 0 auto;
  margin-top: 200px;
  padding-bottom: 150px;
  position: relative;
  .video-title {
    margin: 0 auto;
    width: 644px;
    height: 92px;
    background: #f15922;
    border: 5px solid #ffffff;
    transform: skew(-15deg);
    position: absolute;
    top: -10%;
    left: 50%;
    margin-left: -322px;
    .video-title1 {
      font-size: 34px;
      font-weight: 600;
      line-height: 92px;
      color: #ffffff;
      text-align: center;
      transform: skew(13deg);
    }
  }
  .video-pic {
    margin: 0 auto;
    background: url('../assets/img/1-Box-Images/images_video.png') 100%/100%;
    width: 90%;
    height: 614px;
    border-radius: 8px;
  }
}
</style>

<style lang="scss">
.box-btn {
  background: #f15922 !important;
  border: #fff;
}
.box-btn:hover {
  background: #f15922;
  border: #fff;
}
#Home {
  .info-title {
    padding-top: 100px;
    margin: 0 5%;
    font-size: 26px;
    text-align: left;
    .info-tit {
      font-size: 36px;
      font-weight: 600;
      text-align: center;
    }
  }
  .swiper-pagination {
    text-align: right !important;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets {
    top: 15px;
    right: 45px;
    left: unset !important;
    width: 30%;
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin: 0 6px !important;
      display: inline-block;
      background: #fff;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background: #f15922;
      opacity: 1;
    }
  }
}
.opportunities-job {
  position: absolute;
  bottom: 0;
  width: 1.99rem;
  height: 0.5rem;
  background: #8f98dc;
  transform: skew(-15deg) translateX(-3%);
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  color: #ffffff;
  padding-left: 10px;
  overflow: hidden;
}
#target-expired {
  width: 160px;
  height: 30px;
  background: #b2beb5;
  border: 0;
  border-radius: 15px;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
  margin-right: 5px;
}

.opportunities-vol {
  background: #f15922;
}
.saved {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
}

.check {
  margin: 35px 0;
  display: flex;
  flex-direction: column;
  .check-info {
    display: flex;
    align-items: center;
    line-height: 24px;
    margin: 10px 0;
    input[type='checkbox'] {
      width: 40px;
      height: 40px;
      background-color: #fff;
      -webkit-appearance: none;
      border: 1px solid #d1d4eb;
      border-radius: 50%;
      outline: none;
    }
    input[type='checkbox']:checked {
      background: #d1d4eb;
    }
    .check-right {
      margin-left: 20px;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: #1d1d1d;
    }
  }
}

.ihksport {
  margin: 33px 0 7px 0;
  // width: 91px;
  text-align: left;
  min-height: 20px;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #8f98dc;
}
.info-active {
  margin-bottom: 24px;
  height: 55px;
  // width: 226px;
  text-align: left;
  min-height: 28px;
  font-size: 25px;
  font-weight: bold;
  line-height: 46px;
  color: #28327b;
}
.target-btn {
  // margin: 10px 0;
  width: 160px;
  height: 30px;
  background: #8f98dc;
  border: 0;
  border-radius: 15px;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
  margin-right: 5px;
}

.post-date {
  margin: 5px 10px 5px 0;
  // margin-top: 5px;
  text-align: end;
  color: #999;
  font-size: 16px;
}
.dialog-info {
  height: 1000px;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
  overflow: scroll;
  .dialog-bg {
    height: 370px;
    background: #adadad;
    position: relative;
    overflow: hidden;
    transition: height 2s;
    -webkit-transition: height 2s;
    transition: max-height;
    .group-btn {
      position: absolute;
      right: 30px;
      bottom: 20px;
    }
    .apply-btn {
      width: 200px;
      height: 60px;
      background: #f15922;
      border: 1px solid #f15922;
      border-radius: 4px;
      color: #fff;
    }
    .save-btn {
      width: 200px;
      height: 60px;
      background: #fff;
      border: 1px solid #f15922;
      border-radius: 4px;
      color: #f15922;
    }
  }
  // .dialog-bg:hover {
  //   height: 100%;
  // }
  .dialog-txt {
    padding-left: 30px;
    .info-event {
      text-align: left;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      color: #1d1d1d;
    }
    .txt-int {
      margin-top: 34px;
      display: flex;
      justify-content: space-between;
      padding: 0 50px;
      .intbox {
        width: 20%;
        border-right: 3px solid #d1d4eb;
      }
      .intbox:last-child {
        border-right: 0;
      }
      .int-box {
        position: relative;
        .txt-img {
          width: 40px;
          height: 40px;
        }
        .txt-font1 {
          font-size: 20px;
          text-align: center;
          font-weight: 600;
          line-height: 36px;
          color: #8f98dc;
        }
        .txt-font2 {
          margin-top: 20px;
          text-align: center;
          font-size: 20px;
          font-weight: 400;
          line-height: 36px;
          color: #1d1d1d;
        }
        .txt-line {
          width: 0px;
          height: 130px;
          border: 1px solid #d1d4eb;
          position: absolute;
          right: -50px;
          top: 0;
        }
      }
    }
    .bottom-line {
      margin-top: 30px;
      width: 97%;
      height: 0;
      border: 1px solid #d1d4eb;
    }
    .txt-info {
      text-align: left;
      position: relative;
      .info-box {
        margin: 10px 0;
        display: flex;
        .info-name {
          font-size: 20px;
          width: 270px;
          font-weight: 600;
          line-height: 36px;
          color: #8f98dc;
          display: inline-block;
        }
        .info-value {
          font-size: 20px;
          font-weight: 400;
          line-height: 36px;
          color: #1d1d1d;
          flex: 1;
        }
      }
      .float-right {
        position: relative;
        right: 30px;
        bottom: 0;
      }
    }
  }
}
.dialog2 {
  margin: 0 32px;
  padding-top: 64px;
  height: 400px;
  background: #ffffff;
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}
.confirm-btn {
  margin: 50px auto;
  width: 180px;
  height: 60px;
  background: #f15922;
  border: 1px solid #f15922;
  opacity: 1;
  color: #fff;
  border-radius: 4px;
}
.dialog3 {
  height: 706px;
  background: #ffffff;
  margin: 0 32px;
  padding-top: 64px;
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  .dialog3-info {
    margin: 31px auto;
    width: 1000px;
    // height: 490px;
    background: #ffffff;
    box-shadow: 0px 0px 15px #d1d4eb;
    .dialog3-bg {
      position: relative;
      width: 1000px;
      height: 218px;
      // background: #adadad;
      border-radius: 8px 8px 0px 0px;
    }
    .dialog3-tlt {
      padding-left: 32px;
    }
    .dialog3-txt {
      padding-left: 32px;
      padding-right: 32px;
      display: flex;
      .dialog3-left {
        width: 40%;
        .left-box {
          margin: 10px 0;
          .left-img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .dialog3-right {
        .info-box {
          margin: 10px 0;
          .info-name {
            font-size: 20px;
            width: 270px;
            font-weight: 600;
            line-height: 36px;
            color: #8f98dc;
            display: inline-block;
          }
          .info-value {
            font-size: 20px;
            font-weight: 400;
            line-height: 36px;
            color: #1d1d1d;
          }
        }
      }
    }
  }
}
.paginationStyle {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 0 50px 0;
}
::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #f15922; // 进行修改选中项背景和字体
    color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #f15922;
  }
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-textarea__inner {
    height: 193px;
    background: #f8f8f8;
    border-radius: 10px;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #f15922;
    border-color: #f15922;
  }
  .el-checkbox__inner {
    border-radius: 50%;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #f15922;
  }
}
</style>
<style lang="scss">
@media screen and (max-width: 480px) {
  #Opppryunities {
    .jobs {
      width: 97%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .opportunities {
        width: 49%;
        overflow: hidden;
      }
      .post-date {
        margin: 0;
      }
      // .person-info {
      //     margin-left: 45%;
      // }
    }
    .swiper {
      height: 500px;
      .swiper-item1 {
        .top-right-con {
          width: 40.5%;
          height: 14%;
          transform: skew(-15deg) translateX(7%);
        }
        .box {
          height: 100%;
          width: 53%;
          .img {
            height: 100%;
            width: 120%;
            right: 0%;
          }
        }
      }
    }
    .top-left-con {
      width: 76%;
      height: 368px;
      top: 0;
      left: -16%;
      .bg-tit1 {
        font-size: 35px !important;
        margin-top: 15% !important;
        padding-left: 30%;
      }
      .bg-tit2 {
        margin-top: 2% !important;
        padding-left: 30% !important;
        font-size: 35px !important;
        padding-left: 0;
      }
    }
    .search {
      width: 100%;
    }
    .search-input {
      width: 300px;
      margin: 30px 10px;
      border-radius: 4px;
      transform: skew(15deg) translateX(3%);
    }
    .search-select {
      width: 200px;
      margin: 30px 10px 30px 0;
      border-radius: 4px;
      transform: skew(15deg) translateX(3%);
    }
    .create-btn {
      font-size: 16px;
      width: auto;
    }
    .dialog-info {
      height: auto;
      overflow: scroll;
      .txt-info {
        // display: flex;
      }
    }
    .float-right {
      position: static;
      // right: 30px;
      // bottom: 0;
    }
    .txt-int {
      padding: 0;
    }
  }
}
</style>
